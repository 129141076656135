@font-face {
  font-family: "Circular Std";
  src: url("../src/fonts/CircularStd-Medium.otf");
  font-weight: 500;
}

@font-face {
  font-family: "Circular Std";
  src: url("../src/fonts/CircularStd-Light.otf");
  font-weight: 400;
}

@font-face {
  font-family: "Circular Std";
  src: url("../src/fonts/CircularStd-Book.otf");
  font-weight: 450;
}
.css-1aoogih-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  background-color: #2b59ff !important;
}
html,
body {
  min-height: 100%;

  -webkit-font-smoothing: antialiased;

  font-family: "Circular Std";
}

#__next {
  height: 100%;
}

code {
  /* font-family: 'Public Sans', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue'; */
  font-family: "Circular Std !important";
  font-style: normal;
  padding: 0.1rem 0.4rem;
  font-size: 90%;
  color: #d400ff;
  border-radius: 0.1335rem;
}

code:not([class*="language-"]):before,
code:not([class*="language-"]):after {
  content: "`";
}

code[class*="language-"] {
  padding: 0;
}
.followers-tooltip {
  text-align: center;
  background: #2f3349;
  display: flex;
  flex-direction: column;
  padding: 15px;
  border-radius: 6px;
}

.followers-tooltip-header {
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  color: #ffffff;
}

.followers-tooltip-date {
  font-weight: 400;
  font-size: 11px;
  line-height: 14px;
  color: #ffffff;
}

.pac-container {
  z-index: 9999 !important;
  position: fixed !important;
}

.avg-revenue-tooltip {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65));
  width: 147px;
  height: 46px;
  display: flex;
  flex-direction: column;
  padding: 4px;
  border-radius: 6px;
}

.avg-revenue-tooltip-header {
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: #ffffff;
}

.avg-revenue-tooltip-date {
  font-weight: 400;
  font-size: 11px;
  line-height: 14px;
  color: #ffffff;
}

.your-visitors {
  background-color: #ffffff;
  padding: 20px !important;
  border-radius: 5px;
  border: unset !important;
  display: flex;
  flex-direction: column;
  overflow: visible;
  /* filter: drop-shadow(0px 4px 18px rgba(75, 70, 92, 0.1)); */
  position: relative;
}

.your-visitors::before {
  position: absolute;
  content: "";
  bottom: -20px;
  left: 50%;
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  transform: translate(-50%, -50%);
  border-top: 20px solid #ffffff;
  z-index: 9999;
}

.recharts-custom-tooltip {
  background-color: #424242;
  padding: 7px;
  border-radius: 5px;
}

.your-visitors-tooltip-top {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 21px;
}

.your-visitors-tooltip-value {
  font-style: normal;
  font-weight: 450;
  font-size: 15px;
  line-height: 22px;
}

.round-dot-3 {
  border: 2px solid #ed6c68 !important;
  background-color: #ed6c68 !important;
  height: 13px;
  width: 13px;
  border-radius: 50%;
}

.round-dot-4 {
  border: 2px solid #2b59ff !important;
  background-color: #2b59ff !important;
  height: 13px;
  width: 13px;
  border-radius: 50%;
}

.round-dot-1 {
  border: 2px solid #ddd0fb !important;
  background-color: #ddd0fb !important;
  height: 13px;
  width: 13px;
  border-radius: 50%;
}

.round-dot-2 {
  border: 2px solid #a480f5 !important;
  background-color: #a480f5 !important;
  height: 13px;
  width: 13px;
  border-radius: 50%;
}

.your-visitors-tooltip-header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.competitor-chart {
  background-color: #ffffff;
  padding: 20px !important;
  border-radius: 5px;
  border: unset !important;
  display: flex;
  flex-direction: column;
  overflow: visible;
  filter: drop-shadow(0px 4px 18px rgba(75, 70, 92, 0.1));
  position: relative;
}
.engagement-chart {
  background-color: #ffffff;
  border-radius: 5px;
  border: unset !important;
  display: flex;
  flex-direction: column;
  overflow: visible;
  filter: drop-shadow(0px 4px 18px rgba(75, 70, 92, 0.1));
  position: relative;
}
.competitor-chart::before {
  position: absolute;
  content: "";
  bottom: -20px;
  left: 50%;
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  transform: translate(-50%, -50%);
  border-top: 20px solid #ffffff;
  z-index: 9999;
}

.competitor-chart-tooltip-top {
  margin-bottom: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 21px;
  color: #5d586c;
}

.engagement-chart-tooltip-top svg {
  margin-right: 10px;
}

.engagement-chart-tooltip-top {
  font-size: 18px;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 24px;
  color: #5d586c;
  display: inline-flex;
  min-width: 200px;
  align-items: center;
  margin-bottom: 16px;
  padding: 20px;
  border-bottom: 1px solid #e8dfdf;
}

.competitor-chart-round-dot {
  /* background: #e0cffe; */
  height: 13px;
  width: 13px;
  border-radius: 50%;
}

.competitor-chart-tooltip-header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  margin-bottom: 10px;
  font-size: 15px;
  font-style: normal;
  font-weight: 450 !important;
  line-height: 22px;
  color: #726d85;
}

.engagement-chart-tooltip-body {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 15px;
  gap: 10px;
  font-style: normal;
  font-weight: 450 !important;
  line-height: 21px;
  color: #5d586c;
  padding: 0 0 0 16px;
}

.engagement-chart-tooltip-body:last-child {
  padding: 0 0 16px 16px;
}

.operational-chart-tooltip-header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 13px;
  font-style: normal;
  font-weight: 450;
  line-height: 20px;
  color: #ffffff;
}
.operational-chart-tooltip-header:not(:last-child) {
  margin-bottom: -15px;
}
.visitor-polar-chart {
  background-color: rgba(0, 0, 0, 0.65);
  padding: 20px !important;
  border-radius: 5px;
  border: unset !important;
  display: flex;
  flex-direction: column;
  overflow: visible;
  color: #fff;
  filter: drop-shadow(0px 4px 18px rgba(75, 70, 92, 0.1));
  position: relative;
}

.visitor-polar-chart-tooltip-top {
  margin-bottom: 16px;
  font-style: normal;
  font-weight: 450;
  font-size: 15px;
  line-height: 21px;
}

.visitor-polar-chart-round-dot {
  /* background: #e0cffe; */
  height: 13px;
  width: 13px;
  border-radius: 50%;
}

.visitor-polar-chart-tooltip-header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  margin-bottom: 10px;
}

.leads-chart {
  background-color: rgba(0, 0, 0, 0.65);
  padding: 20px !important;
  border-radius: 5px;
  border: unset !important;
  display: flex;
  flex-direction: column;
  overflow: visible;
  filter: drop-shadow(0px 4px 18px rgba(75, 70, 92, 0.1));
  position: relative;
}

/* .leads-chart::before {
  position: absolute;
  content: '';
  bottom: -20px;
  left: 50%;
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  transform: translate(-50%, -50%);
  border-top: 20px solid #ffffff;
  z-index: 9999;
} */
.leads-tooltip-top {
  margin-bottom: 16px;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: rgba(255, 255, 255, 1);
  line-height: 21px;
}

.leads-chart-round-dot {
  /* background: #e0cffe; */
  height: 13px;
  width: 13px;
  border-radius: 50%;
}

.leads-chart-tooltip-header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  margin-bottom: 10px;
  font-size: 13px;
  color: rgba(255, 255, 255, 1);
  /* line-height: 21px; */
}

.New-Patients-chart {
  background-color: rgba(0, 0, 0, 0.65);
  width: 170px;
  height: 50px;
  padding: 4px;
  border-radius: 6px;
}

.New-Patients-chart-tooltip-top {
  /* font-family: 'Public Sans';
   */
  font-family: "Circular Std !important";
  font-style: normal;
  font-size: 13px;
  color: rgba(255, 255, 255, 1);
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
}

.New-Patients-chart-tooltip-header {
  font-size: 13px;
  color: rgba(255, 255, 255, 1);
  /* font-family: 'Public Sans'; */
  font-family: "Circular Std !important";
  font-style: normal;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
}

.collection-report {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)), #8692d0;
  border-radius: 6px;
  width: 100%;
  min-width: 181px;
  height: 100%;
  padding: 4px;
}

.margin {
  margin-top: 10px !important;
}

.collection-report-tooltip-top {
  /* font-family: 'Public Sans'; */
  font-family: "Circular Std !important";
  font-style: normal;
  font-size: 13px;
  color: rgba(255, 255, 255, 1);
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
}

.collection-report-tooltip-header {
  /* font-family: 'Public Sans'; */
  font-family: "Circular Std !important";
  font-style: normal;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #ffffff;
  display: flex;
  align-items: center;
  gap: 6px;
}

.collection-report-tooltip-bttom {
  margin-top: 20px;
  /* font-family: 'Public Sans'; */
  font-family: "Circular Std !important";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  color: #ffffff;
}

.round-dot-collection-report {
  border: 2px solid #ffe802;
  height: 13px;
  width: 13px;
  border-radius: 50%;
  background-color: transparent;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.round-dot-collection-report2 {
  border: 2px solid #666ee8;
  height: 13px;
  width: 13px;
  border-radius: 50%;
  background-color: transparent;
}

@media screen and (max-width: 1200px) {
  .awesome-news {
    text-align: center;
  }

  .center {
    justify-content: center;
  }

  .icons {
    margin-left: auto;
    margin-right: auto;
  }
}

.cust-rep-card {
  background-color: white;
  color: #4b465c;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8px;
  width: 140px;
  height: auto;
}

.op-dash-card {
  height: 40px;
  width: 46px;
  left: 0px;
  top: 0px;
  border-radius: 0px;
  padding: 8px 9px 8px 9px;
}

.custom-tooltip {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4px;
  width: 180px;
  height: 50px;
  color: white;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)), #8692d0;
}

/* OFR TOOLTIP CUSTOM */

.tooltip {
  position: relative;
  display: inline-block;
  z-index: 1;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 225px;
  background-color: #2f3349;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  font-size: 11px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 125%; /* Changed from bottom */
  left: 50%;
  margin-left: -115px;
  opacity: 0;
  transition: opacity 0.3s;
  padding: 2px;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%; /* Changed from top */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #555 transparent; /* Changed border-color */
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

/* ///////////////////// */
/* left bottom center/ */

.tooltipleft {
  position: relative;
  display: inline-block;
  z-index: 1;
}

.tooltipleft .tooltiptext {
  visibility: hidden;
  width: 225px;
  background-color: #2f3349;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  font-size: 11px;
  padding: 5px 0;
  position: absolute;
  z-index: 2;
  top: 125%; /* Changed from bottom */
  left: -400%;
  margin-left: -115px;
  opacity: 0;
  padding: 5px;
  transition: opacity 0.3s;
}

.tooltipleft .tooltiptext::after {
  content: "";

  position: absolute;
  bottom: 100%; /* Changed from top */
  /* left: 50%; */
  right: 7%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #555 transparent; /* Changed border-color */
}

.tooltipleft:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

/* FOR PAID ADS */

.tooltipleft-paidads {
  position: relative;
  display: inline-block;
  z-index: 1;
}

.tooltipleft-paidads .tooltiptext {
  visibility: hidden;
  width: 225px;
  background-color: #2f3349;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  font-size: 11px;
  position: absolute;
  z-index: 1;
  top: 60%;
  left: -400%;
  margin-left: -115px;
  opacity: 0;
  padding: 5px;
  transition: opacity 0.3s;
}

.tooltipleft-paidads .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%; /* Changed from top */
  /* left: 50%; */
  right: 7%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #555 transparent; /* Changed border-color */
}

.tooltipleft-paidads:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
.mapboxgl-popup-content {
  background: #2f3349 !important;
  color: #ffffff;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  border-radius: 6px;
  width: 151px;
  padding: 4px !important;
}
.mapboxgl-popup {
  left: -33px !important;
}
.mapboxgl-popup-tip {
  border-left-color: #2f3349 !important;
  border: 6px solid transparent;
}
.mapboxgl-ctrl-attrib-inner,
.mapboxgl-ctrl-logo,
.mapboxgl-ctrl-bottom-right {
  display: none !important;
}

/*Styles for reporting screen                                                       */
.table-report thead th {
  text-align: center;
  width: 100px;
}

.table-report td {
  text-align: right;
  height: 10%;
  /* border-width: 1px; */
  width: fit-content;
}

.table-report {
  height: 10%;
  width: 100%;
  margin-bottom: 10px;
}

.table-border {
  border: 1px solid #000;
}

.table-report > tbody > tr > th {
  width: 173px;
  max-width: 160px;
  text-align: start;
  /* font-weight: 700; */
  color: black;
  /* font-size: 12px; */
}

.table-report > tbody > tr > td {
  width: 173px;
  max-width: 160px;
  color: #000;
  /* font-size: 12px; */
  /* font-weight: 500; */
}

.table-report caption {
  float: left;
  vertical-align: center;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  min-width: 10px;
  max-width: 34px;
  -webkit-writing-mode: vertical-rl;
  -ms-writing-mode: vertical-rl;
}

.title-table {
  width: 5px !important;
  padding: 10px;
  background-color: lightgrey;
  border-right: 2px solid lightgrey;
  vertical-align: center;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  -webkit-writing-mode: vertical-rl;
  -ms-writing-mode: vertical-rl;
  -webkit-text-orientation: mixed;
}

.date-th {
  width: 25px !important;
  /* max-width: 18px !important; */
  text-align: center;
  background-color: #dddddd;
}

.table-dates {
  text-align: center;
}

.table-report tr:nth-child(even) {
  background-color: #dddddd;
}
.table-report tr:nth-child(odd) {
  background-color: #ffffff;
}

.table-col {
  /* display: flex;
  justify-content: flex-center; */
  width: 50px;
  max-width: 173px;
}

.table-cols {
  display: flex;
  justify-content: flex-end;
  padding-right: 5px;
}

@media print {
  html body {
    margin: 30px;
  }

  .table-report caption {
    float: left;
    vertical-align: center;
    writing-mode: vertical-rl;
    text-orientation: mixed;
    min-width: 10px;
    max-width: 34px;
    -webkit-writing-mode: vertical-rl;
    -ms-writing-mode: vertical-rl;
  }

  .date-th {
    width: 10px !important;
    max-width: 18px !important;
    text-align: center;
    background-color: #ffffff;
  }

  .title-table {
    width: 5px !important;
    padding: 10px;
    background-color: #ffffff;
    vertical-align: center;
    writing-mode: vertical-rl;
    text-orientation: mixed;
    -webkit-writing-mode: vertical-rl;
    -ms-writing-mode: vertical-rl;
    -webkit-text-orientation: mixed;
  }

  .table-report {
    font-family: Roboto, sans-serif;
    font-size: small;
    table-layout: fixed;
    border-collapse: collapse;
    border: 1px solid #808080 !important;
    /* border: 4px solid red !important; */
    --webkit-print-color-adjust: exact;
  }

  .table-report tr:nth-child(even) {
    background-color: #ffffff;
  }
  .table-report tr:nth-child(odd) {
    background-color: #ffffff;
  }

  .table-report tr {
    font-family: Roboto, sans-serif;
    font-size: small;
    border: 1px solid #808080 !important;
    /* border: 1px solid #000 !important; */
    background-color: white;
    --webkit-print-color-adjust: exact;
  }
  .table-report td {
    font-family: Roboto, sans-serif;
    font-size: small;
    font-weight: 700 !important;
    border: 1px solid #808080 !important;
    /* border: 1px solid black !important; */
    /* color: #000 !important; */
    /* min-width: 229px; */
    --webkit-print-color-adjust: exact;
    font-size: 10px !important;
    height: 10%;
    padding-right: 5px !important;
  }
  .table-report th {
    font-family: Roboto, sans-serif;

    border: 1px solid #808080 !important;
    /* border: 1px solid black !important; */
    color: #000 !important;
    --webkit-print-color-adjust: exact;
  }

  .table-col {
    width: 70px;
    max-width: 173px;
    font-size: 12px;
  }

  .table-col span {
    font-size: 10px !important;
  }

  .table-cols {
    display: flex;
    justify-content: flex-end;
    padding-right: 5px;
  }

  .table-report > tbody > tr > th {
    width: 158px !important;
    max-width: 173px !important;
    font-size: 9px;
    padding: 2px;
    padding-left: 5px;
  }
  table {
    page-break-inside: avoid;
  }

  tr {
    page-break-inside: avoid;
    page-break-after: auto;
  }

  thead {
    display: table-header-group;
  }

  tfoot {
    display: table-footer-group;
  }
}

.slick-slider .slick-slide {
  box-shadow: none !important;
  border: none !important;
}

.custom-slider .slick-dots {
  bottom: 0;
  top: 30px;
  width: auto;
  right: 50px;
}
.custom-slider .slick-dots li button:before {
  color: #fff !important;
  font-size: 20px;
  width: 40px;
}

.testimonials-slider .slick-dots li button:before {
  color: #a4a4a4 !important;
  font-size: 14px;
  width: 14px;
}

.testimonials-slider .slick-dots li.slick-active button:before {
  color: #1e54f5 !important;
  font-size: 14px;
  width: 14px;
}
